import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Events from "../components/Events";
import { Main } from "../styles";
import HeaderBackground from "../components/HeaderBackground";
import Header from "../components/Header";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <HeaderBackground title="EVENTS" />
      <Main>
        <Events />
      </Main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
