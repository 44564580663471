import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Http from "../helpers/Http";
import Template from "./Template";
import eventIcon from "../images/event.png";

const ProgramsAndEvent = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/4")
        .then((response) => {
          setEvents(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Container>
      <Template data={events} image={eventIcon} type="EVENT" />
    </Container>
  );
};

export default ProgramsAndEvent;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;
